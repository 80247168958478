body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E5E9EB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
div,
span,
svg,
label,
input,
textarea,
i,
button,
select {
  font-family: 'Inter', sans-serif;
  margin: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(70, 70, 70, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  border-radius: 10px;
  // border: 2px solid #8f8f8f;
}

.ant-btn {
  border-radius: 6px;
  height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.006em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary {
  background-color: #4094f7;
}

.ant-input {
  border: 1px solid #dde2e4;
  box-sizing: border-box;
  border-radius: 6px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;

  padding: 8px 16px;

  &::placeholder {
    color: #9aa6ac;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.ant-form-item-label {
  > label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.006em;
    color: #252c32;
  }
}

.wrapper {
  width: 100%;
  min-height: 100vh;

  .contentWrapper {
    padding-top: 56px;
    overflow: hidden;
    width: 100%;
  }
}
