.header {
  background: #fff;
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  padding: 0 42px;
  border-bottom: 1px solid #e5e9eb;

  .logo {
    line-height: auto;
    display: inline-flex;
    align-items: center;
  }

  .ant-menu {
    background: #fff;
    height: 56px;

    .ant-menu-item {
      color: #5b6871;
    }
  }

  .mainMenus {
    margin-left: auto;
    height: 100%;
    display: inline-flex;
    align-items: center;

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.006em;
      color: #5b6871;
      display: inline-flex;
      padding: 5px;
    }
  }
}

.ant-popover {
  .ant-menu {
    border: none !important;
  }
}

@media (max-width: 1024px) {
  .header {
    padding: 0 24px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0 16px;
  }
}
